import "./About.scss";
import headshot from "../../assets/images/Yakiv-Headshot-2022.jpg";

function About() {

  return (
    <>
      <div className="about__container" id="about__id">
        <section className="about__section">
          <img
            className="about__image"
            src={headshot}
            alt="author headshot"
          ></img>
          <div className="about__content">
            <h1 className="about__title">About Me.</h1>

            <p className="about__text">
              I have always been driven by my passion for creativity which first
              came in the form of music production. Working in a setting where
              the requirements of the project are always changing has allowed me
              to develop many valuable skills such as adapting to a fast-paced
              environment, working/communicating effectively with a team, and
              adopting a more agile approach to my workflow. After learning the
              basics of web development on my own I realized that I found more
              creative fulfillment and I was able to simultaneously develop my
              critical thinking capabilities. This experience has ultimately led
              to step into the vast world software engineering where I will
              bring my newfound approach to help others overcome unique
              challenges.
            </p>
          </div>
        </section>
        <section className="about__addition"></section>
      </div>
    </>
  );
}

export default About;
