import "./Projects.scss";

import kollabImage from "../../assets/images/kollab.png";
import inStockImage from "../../assets/images/instock.png";
import brainflixImage from "../../assets/images/brainflix.jpg";

function Projects() {
  return (
    <section className="projects">
      <h2 className="projects__heading">Projects</h2>
      <div className="projects__container container">
        <div className="container__item item" id="projects__id">
          <div className="item__left">
            <div className="item__wrapper">
              <img src={kollabImage} className="item__image"></img>
            </div>
          </div>
          <div className="item__right">
            <h3 className="item__title">Kollab</h3>
            <p className="item__text">
              A collaborative platform that connects producers and artists in a
              shared environment. Users are able to share and access new ideas,
              or build on tracks uploaded by collaborators within their network.
            </p>
            <div className="item__buttons">
              <a href="https://kollab-wav.vercel.app/">
                {" "}
                <button className="item__button">Demo</button>
              </a>
              <a href="https://github.com/yakiv-k/kollab-client">
                <button className="item__button--alt">Code</button>
              </a>
            </div>
          </div>
        </div>
        <div className="container__item item">
          <div className="item__left">
            <div className="item__wrapper">
              <img src={inStockImage} className="item__image"></img>
            </div>
          </div>
          <div className="item__right">
            <h3 className="item__title">inStock</h3>
            <p className="item__text">
              An scalable inventory management system developed over the course
              of 5 days with the help of two other developers.
            </p>
            <div className="item__buttons">
              <a href="https://instock-project.netlify.app/">
                {" "}
                <button className="item__button">Demo</button>
              </a>
              <a href="https://github.com/yakiv-k/inStock">
                <button className="item__button--alt">Code</button>
              </a>
            </div>
          </div>
        </div>
        <div className="container__item item">
          <div className="item__left">
            <div className="item__wrapper">
              <img src={brainflixImage} className="item__image"></img>
            </div>
          </div>
          <div className="item__right">
            <h3 className="item__title">BrainFlix</h3>
            <p className="item__text">
              A platform that provides users with a modern video streaming
              experience including a list of available videos, their details,
              and user comments.
            </p>
            <div className="item__buttons">
              <a href="https://brainflix-deployed.vercel.app/">
                {" "}
                <button className="item__button">Demo</button>
              </a>
              <a href="https://github.com/yakiv-k/BrainFlix">
                {" "}
                <button className="item__button--alt">Code</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Projects;
