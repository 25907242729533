
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import About from "./components/About/About.js"
import Skills from "./components/Skills/Skills";
import Projects from "./components/Projects/Projects";
import Contact from "./components/Contact/Contact";

import "./styles/App.scss";

function App() {
  return (
    <div>
      <Header />
      <Home />
      <About />
      <Skills />
      <Projects />
      <Contact />
    </div>
  );
}

export default App;

// components:
// header - navigation
// home - welcome section introducing me
// about - background in depth
// skills - showcase current skills and tech stack
// work - showcase projects
// contact - contact form
