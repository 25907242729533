import "./Header.scss";
import { HiOutlineNewspaper } from "react-icons/hi";
import { BsGithub } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";

import handleClickScroll from "../../functions/functions";

function Header() {
  return (
    <div className="header">
      <nav className="header__nav nav">
        <div className="nav__icons">
          <a className="nav__icon" href="https://drive.google.com/file/d/16GL7OpyPb0TaG9gafDBs1chxFimQ9ZVx/view?usp=sharing">
            {" "}
            <HiOutlineNewspaper color="white" size={23} className="nav__portal"/>
          </a>
          <a className="nav__icon"  href="https://github.com/yakiv-k">
            {" "}
            <BsGithub color="white" size={23} className="nav__portal"/>
          </a>
          <a className="nav__icon" href="https://www.linkedin.com/in/yakiv-k/">
            {" "}
            <FaLinkedinIn color="white" size={23} className="nav__portal"/>
          </a>
        </div>
        <div className="nav__section">
          <ul className="nav__directory">
            <li
              className="nav__link"
              onClick={() => {
                handleClickScroll("home__id");
              }}
            >
              Home
            </li>
            <li
              className="nav__link"
              onClick={() => {
                handleClickScroll("about__id");
              }}
            >
              About
            </li>
            <li
              className="nav__link"
              onClick={() => {
                handleClickScroll("skills__id");
              }}
            >
              Skills
            </li>
            <li
              className="nav__link"
              onClick={() => {
                handleClickScroll("projects__id");
              }}
            >
              Projects
            </li>
            <li
              className="nav__link"
              onClick={() => {
                handleClickScroll("contact__id");
              }}
            >
              Contact
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Header;
