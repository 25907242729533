import "./Skills.scss";

import htmlIcon from "../../assets/images/html5.png";
import cssIcon from "../../assets/images/css3.png";
import jsIcon from "../../assets/images/js.png";
import reactIcon from "../../assets/images/react.png";
import scssIcon from "../../assets/images/scss.png";
import nodeIcon from "../../assets/images/nodejs.png";
import knexIcon from "../../assets/images/knex.png";
import mysqlIcon from "../../assets/images/mysql.png";
import gitIcon from "../../assets/images/github.png";
import expressIcon from "../../assets/images/express.png";

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

function Skills () {
    return (
        <div className="skills" id="skills__id">
            <div className="skills__text">
                <h2 className="skills__title">Skills</h2>
                <p className="skills__info">My current tech stack.</p>
            </div>
            <div className="skills__container">
                <div className="skills__item">
                <p className="skills__item-title">HTML</p>
                    <img className="skills__image" src={htmlIcon} alt="skill icon"></img>
                </div>
                <div className="skills__item">
                <p className="skills__item-title">CSS</p>
                    <img className="skills__image" src={cssIcon} alt="skill icon"></img>
                </div>
                <div className="skills__item">
                <p className="skills__item-title">JavaScript</p>
                    <img className="skills__image" src={jsIcon} alt="skill icon"></img>
                </div>
                <div className="skills__item">
                <p className="skills__item-title">React</p>
                    <img className="skills__image" src={reactIcon} alt="skill icon"></img>
                </div>
                <div className="skills__item">
                <p className="skills__item-title">SCSS</p>
                    <img className="skills__image" src={scssIcon} alt="skill icon"></img>
                </div>
                <div className="skills__item">
                <p className="skills__item-title">Node</p>
                    <img className="skills__image" src={nodeIcon} alt="skill icon"></img>
                </div>
                <div className="skills__item">
                <p className="skills__item-title">Knex</p>
                    <img className="skills__image" src={knexIcon} alt="skill icon"></img>
                </div>
                <div className="skills__item">
                <p className="skills__item-title">MySQL</p>
                    <img className="skills__image" src={mysqlIcon} alt="skill icon"></img>
                </div>
                <div className="skills__item">
                <p className="skills__item-title">Express</p>
                    <img className="skills__image" src={expressIcon} alt="skill icon"></img>
                </div>
                <div className="skills__item">
                <p className="skills__item-title">GitHub</p>
                    <img className="skills__image" src={gitIcon} alt="skill icon"></img>
                </div>
            </div>
        </div>
    )
}

export default Skills;