import "./Contact.scss";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import doodle from "../../assets/images/doodle-plane.png";
import doodleContact from "../../assets/images/doodle-contact.png";

function Contact() {
  return (
    <section className="contact" id="contact__id">
      {/* <h2 className="contact__heading">Contact</h2> */}
      <div className="contact__container">
        <div className="contact__info info">
          <h3 className="info__heading">Contact</h3>
          <p className="contact__heading">Let's Work</p>
          <img src={doodle} className="contact__image"></img>

          {/* <p className="contact__bio">
            If you're looking for someone who’s highly motivated and who enjoys
            being pushed creatively, let's chat! Submit the form below or shoot
            me an e-mail - yakiv.y.k@gmail.com
          </p> */}
          {/* <ul className="contact__bullets">
            <li>6473005503</li>
            <li>yakiv.y.k@gmail.com</li>
            <li>Toronto, Ontario</li>
          </ul> */}
        </div>
        <form
          className="contact__form"
          action="https://getform.io/f/de26bbc9-09c4-4055-ac72-59f8f3be3d4b"
          method="POST"
        >
          <h2 className="contact__form-heading">Get in touch</h2>
          <label className="contact__label">
            Your Name<em className="contact__highlight">*</em>
          </label>
          <input
            className="contact__input"
            placeholder="Name"
            type="text"
            name="name"
          ></input>
          <label className="contact__label">
            Your Email<em className="contact__highlight">*</em>
          </label>
          <input
            className="contact__input"
            placeholder="Email"
            type="text"
            name="email"
          ></input>
          <label className="contact__label">
            Message<em className="contact__highlight">*</em>
          </label>
          <textarea
            rows="8"
            className="contact__input--extended"
            placeholder="Message"
            type="text"
            name="message"
          ></textarea>
          <div className="contact__lower">
            <button
              className="contact__button"
              onSubmit={(e) => e.preventDefault()}
            >
              Send{" "}
              <NavigateNextIcon style={{ color: "#3f51b5", fontSize: "20" }} />
            </button>
            <img src={doodleContact} className="contact__image-2"></img>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Contact;
