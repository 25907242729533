import "./Home.scss";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import handleClickScroll from "../../functions/functions";
import { useRef, useEffect } from "react";
import { gsap, Power1 } from "gsap";
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

function Home() {
  const landingRef = useRef(null);

  useEffect(() => {
    const el = landingRef.current;
    const anim = gsap.fromTo(el, {autoAlpha: 0, y: 50}, {duration: 1, autoAlpha: 1, y: 0, ease: Power1.easeInOut});
  }, [])

  return (
    <div className="landing" id="home__id" ref={landingRef}>
      <section className="landing__heading">
        <h1 className="landing__title">Hi, I'm Yakiv.</h1>
        <p className="landing__sub-title">
          I'm a full-stack developer based in Toronto.
        </p>
        <p className="landing__bio">
         Passionate about providing innovative solutions, better UI, and cleaner code.
        </p>
        <button className="landing__button" onClick={()=> {handleClickScroll('about__id')}}>Learn More <NavigateNextIcon/></button>
      </section>
    </div>
  );
}

export default Home;


